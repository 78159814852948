<template>
  <div>
    <div class="loading" style="height: 300px" v-if="loading">
      <Loading />
    </div>
    <FilmLinksItem v-else :links="links_data" />
  </div>
</template>

<script>
  import api from "../../services/api";
  import FilmLinksItem from "./FilmLinksItem";
  import Loading from "../Loading";

  export default {
    name: "FilmsLinks",
    components: {Loading, FilmLinksItem},
    props: ['links'],
    data() {
      return {
        links_data: [],
        loading: true,
        linksId: null
      }
    },
    async mounted() {
      await this.getLinks(this.links.id);
    },
    methods: {
      async getLinks(id) {
        this.loading = true;
        this.links_data = await api.getLinkList(id);
        this.loading = false;
      }
    },
    watch: {
      links: async function(newVal) {
        if (this.linksId !== newVal.id) {
          await this.getLinks(newVal.id)
        }

        this.linksId = newVal.id
      }
    }
  }
</script>

<style scoped>

</style>
