<template>
  <div>
    <div class="faq-accordion">
      <ul class="accordion accordion-2">
        <li class="accordion-item" v-for="(item, name, index) in linkList" :key="name" v-if="item.items.length">
          <a class="accordion-title" :class="{'active' : index === 0}" href="javascript:void(0)">
            <i class="fa fa-plus"></i>
            {{ item.name }}
          </a>
          <div class="accordion-content" :style="[index === 0 ? {'display': 'block'} : '']">
            <div class="post-wrap">
              <ul>
                <li v-for="link in item.items" :key="link.id" v-if="link.button_url">
                  <a target="_blank" :href="link.button_url" rel="noreferrer">{{link.button_text}}</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";

  export default {
    name: "FilmLinksItem",
    props: ['links'],
    data() {
      return {
        linkList: {
          watch: {
            name: 'Ways to Watch',
            items: []
          },
          information: {
            name: 'Reviews & Articles',
            items: []
          },
          press: {
            name: 'Details',
            items: []
          }
        },
      }
    },
    created() {
      for (let link of this.links) {
        if (link.button_type === 'Watch') {
          this.linkList.watch.items.push(link)
        }
        if (link.button_type === 'Press') {
          this.linkList.press.items.push(link)
        }
        if (link.button_type === 'Information') {
          this.linkList.information.items.push(link)
        }
      }
    },
    mounted() {
      $(function() {
        $('.accordion.accordion-2').find('.accordion-title').on('click', function(){
          // Adds Active Class
          $(this).toggleClass('active');
          // Expand or Collapse This Panel
          $(this).next().slideToggle('fast');
          // Hide The Other Panels
          $('.accordion-content').not($(this).next()).slideUp('fast');
          // Removes Active Class From Other Titles
          $('.accordion-title').not($(this)).removeClass('active');
        });
      });
    }
  }
</script>

<style scoped>
  .faq-accordion .accordion .accordion-item {
    border: none;
  }
</style>
