<template>
  <div id="film-details">
    <div class="loading" v-if="film_data === undefined && !film_error">
      <Loading />
    </div>
    <div v-if="film_data">
      <div class="page-title-area padding-50-pr bg-11" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
        <div class="container">
          <div class="page-title-content">
            <h2>{{film_data.data[0].title ? film_data.data[0].title : 'Title'}}</h2>

            <ul>
              <li class="active">{{film_data.data[0].subtitle}}</li>
            </ul>
          </div>
        </div>
      </div>

      <section class="blog-details-area ptb-100" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12">
              <div class="blog-details-desc">
                <div class="article-content">
                  <div class="article-image validating">
                    <DefaultImage v-if="film_data.data[0].image" :id="film_data.data[0].image.id" />
                  </div>

                  <p class="white-space-pre-wrap remove-white-space" v-html="film_data.data[0].description"></p>

                  <div class="gallery-wrapper">
                    <div class="gallery-content" v-if="film_data.data[0].award_icons.length">
                      <div class="gallery-items" v-for="(image, i) in film_data.data[0].award_icons" :key="i" @click="openGallery(image.directus_files_id)">
                        <DefaultImage :id="image.directus_files_id" :height="'100%'" :class-name="'donate-page'" />
                      </div>
                    </div>

                    <modal name="gallery" class="gallery-modal" width="800px" height="500px">
                      <DefaultImage :id="images" height="100%" width="100%" />
                      <div class="cancel-btn" @click="$modal.hide('gallery')">
                        <i class="fa fa-times"></i>
                      </div>
                    </modal>
                  </div>
                </div>

                <div class="article-footer">
                  <div class="article-tags">
                    <span><i class='fa fa-share-alt'></i></span>

                    <a href="#">Share</a>
                  </div>

                  <div class="article-share">
                    <ul class="social">
                      <li>
                        <ShareNetwork
                          network="facebook"
                          :url="base_url"
                          :title="film_data.data[0].title"
                          :description="film_data.data[0].description"
                        >
                          <i class="flaticon-facebook" aria-hidden="true"></i>
                        </ShareNetwork>
                      </li>
                      <li>
                        <ShareNetwork
                          network="twitter"
                          :url="base_url"
                          :title="film_data.data[0].title"
                          :description="film_data.data[0].description"
                        >
                          <i class="flaticon-twitter"></i>
                        </ShareNetwork>
                      </li>
                      <li>
                        <ShareNetwork
                          network="linkedin"
                          :url="base_url"
                          :title="film_data.data[0].title"
                          :description="film_data.data[0].description"
                        >
                          <i class="flaticon-linkedin"></i>
                        </ShareNetwork>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12">
              <GetDVDCopyDialog :title="film_data.data[0].title" />

              <aside class="widget-area" id="secondary">

                <FilmsLinks v-if="film_data" :links="film_data.data[0]" />

                <div class="loading" v-if="allFilms_data === undefined && !allFilms_error" style="height: 300px;">
                  <Loading />
                </div>
                <section class="widget widget-peru-posts-thumb" v-if="allFilms_data">
                  <h3 class="widget-title">Nobel Films</h3>
                  <div class="post-wrap">
                    <div v-if="!allFilms_data.data.length">
                      No Films
                    </div>
                    <article v-else class="item" v-for="film in allFilms_data.data" :key="film.id">
                      <router-link :to="{ name: 'film', params: { url: film.slug } }" class="thumb">
                        <DefaultImage :id="film.image" :height="'80px'" :width="'80px'" />
                      </router-link>
                      <div class="info">
                        <time>{{moment(film.published)}}</time>
                        <h4 class="title usmall">
                          <router-link :to="{ name: 'film', params: { url: film.slug } }">
                            {{film.title}}
                          </router-link>
                        </h4>
                      </div>

                      <div class="clear"></div>
                    </article>
                  </div>
                </section>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import api from "../services/api";
  import Loading from "../components/Loading";
  import DefaultImage from "../components/DefaultImage";
  import useSWRV from "swrv";
  import FilmsLinks from "../components/films/FilmsLinks";
  import moment from "moment";
  import GetDVDCopyDialog from "@/components/GetDVDCopyDialog";

  export default {
    name: "FilmDetails",
    components: {
      GetDVDCopyDialog,
      FilmsLinks,
      DefaultImage,
      Loading
    },
    data() {
      return {
        base_url: window.location.href,
        loading: true,
        loadingGallery: true,
        loadingFilms: true,
        index: null,
        images: '',
        loadingLinks: true,
        changeRoute: false
      }
    },
    setup(props, { root }) {
      if (!root.$route.params.url) {
        root.$router.push({ name: '/' });
      }
      const {
        data: film_data,
        error: film_error,
        isValidating: film_isValidating
      } = useSWRV(() => `items/films?status=published&filter[slug]=${root.$route.params.url}&fields=id,description,image,published,slug,status,subtitle,title,award_icons.*`, api.fetcher);

      const {
        data: allFilms_data,
        error: allFilms_error,
        isValidating: allFilms_isValidating
      } = useSWRV(() => `items/films?status=published&sort=-published`, api.fetcher);

      return {
        film_data,
        film_error,
        film_isValidating,

        allFilms_data,
        allFilms_error,
        allFilms_isValidating
      };
    },
    watch: {
      '$route.params': {
        immediate: true,
        handler() {
          window.scrollTo(0, 0);
        }
      }
    },
    methods: {
      moment(date) {
        return moment(date).format("YYYY");
      },
      openGallery(image) {
        this.images = image;
        this.$modal.show('gallery')
      },
    },
  }
</script>

<style scoped lang="scss">
  .validating {
    position: relative;

    &.isValidating:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      background-color: #fff;
    }
  }
  .loading {
    height: 800px;

    &.gallery {
      height: 400px;
    }
  }
  .gallery-content {
    display: flex;
    flex-wrap: wrap;

    .gallery-items {
      width: 33.33%;
      height: 250px;
      cursor: zoom-in;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .page-title-area .page-title-content ul .active {
    font-size: 20px;
  }
  .blog-details-area .blog-details-desc .article-footer .article-share .social li a {
    margin-left: 5px;
    cursor: pointer;
  }

  .gallery-modal {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
    .cancel-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 20px;
        color: #000;
      }
    }
  }

  @media (max-width: 767px) {
    .gallery-content {
      .gallery-items {
        width: 50%;
      }
    }
  }

  @media (max-width: 500px) {
    .gallery-content {
      .gallery-items {
        width: 100%;
      }
    }
  }
</style>
