<template>
  <div class="get-DVD">
    <div class="get-DVD__button" @click="showPopup">
      Get DVD copy
    </div>

    <modal
      name="getDVD"
      height="auto"
      width="600px"
      :adaptive="true"
      :resizable="true"
    >
      <div class="modal-wrapper">
        <div class="close-btn" @click="$modal.hide('getDVD')"><i class="fa fa-close"></i></div>
        <div class="modal-body">
          <div class="modal-body__title">
            Get DVD copy
          </div>
          <div class="modal-body__text">
            Please enter your email and one of our film staff members will contact you for delivery information.
          </div>
          <form ref="DVDForm" id="contactForm" action="https://flexibleforms.co/f/b681w7vkOOdMR5DhwF9P" @submit.prevent="handleSubmit('DVDForm')">
            <div class="form-group">
              <input
                  type="text"
                  v-model="contactForm.email"
                  id="email"
                  name="email"
                  class="form-control"
                  placeholder="Email"
                  :class="{ 'is-invalid': submitted && $v.contactForm.email.$error }"
              />
              <div v-if="submitted && $v.contactForm.email.$error" class="help-block with-errors">
                <span v-if="!$v.contactForm.email.required">Email is required</span>
                <span v-if="!$v.contactForm.email.email">Email is invalid</span>
                <span v-if="!$v.contactForm.email.maxLength">Email max length is 80</span>
              </div>
            </div>
            <div class="hidden-section">
              <input name="Film name" :value="title" type="text">
              <input type="checkbox" name="notarobot" />
              <input type="checkbox" id="horns" name="horns">
            </div>
            <button class="submit-email-btn" type="submit">Submit</button>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {email, maxLength, required} from "vuelidate/lib/validators";

export default {
  name: "GetDVDCopyDialog",
  data() {
    return {
      submitted: false,
      contactForm: {
        email: ''
      }
    }
  },
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  validations: {
    contactForm: {
      email: { required, email,  maxLength: maxLength(80) },
    }
  },
  methods: {
    showPopup() {
      this.$modal.show('getDVD');
    },
    handleSubmit(formName) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$refs[formName].submit()
    }
  }
}
</script>

<style lang="scss">
.get-DVD {
  &__button {
    padding: 20px 30px 20px 30px;
    color: #1a1b1e;
    text-decoration: none;
    position: relative;
    display: block;
    font-size: 20px;
    font-weight: 600;
    transition: 0.5s;
    box-shadow: 0 0 1.25rem #6c76861a;
    background: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .vm--modal {
    .modal-wrapper {
      padding: 30px;
      position: relative;
      .modal-body {
        &__title {
          font-size: 20px;
          font-weight: 600;
          color: #1a1b1e;
          margin-bottom: 10px;
        }
        &__text {
          margin-bottom: 20px;
        }
        .with-errors {
          color: #dc3545;
          font-size: 13px;
        }
      }
      .submit-email-btn {
        margin-top: 7px;
        display: inline-block;
        font-size: 1em;
        padding: 10px 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: linear-gradient(45deg, #786CF0, #8e84f6);
        color: #fff;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        position: relative;
        transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
      }
      .form-control {
        border-radius: 0;
        height: 50px;
        padding: 10px 15px;
        font-size: 14px;
      }
      .hidden-section {
        overflow: hidden;
        width: 0;
        height: 0;
        opacity: 0;
      }
    }
  }
}
</style>
